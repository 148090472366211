import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/userSlice'
import cartReducer from '../slices/cartSlice'
import productListReducer from '../slices/productListSlice'
import packageListReducer from '../slices/packageListSlice'
import imageReducer from '../slices/imageSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage
}

const persistedUserReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    cart: cartReducer,
    product: productListReducer,
    package: packageListReducer,
    images: imageReducer
  }
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
