import {
  createSlice,
  createAsyncThunk,
  type PayloadAction
} from '@reduxjs/toolkit'
import newImageService from '../services/newImageService'
import { type ImageType } from '../utils/types'

// Async Thunks for Fetching, Uploading, and Deleting Images
export const fetchImages = createAsyncThunk('images/fetchImages', async () => {
  const images = await newImageService.listImages()
  return images
})

export const uploadImages = createAsyncThunk(
  'images/uploadImages',
  async (files: File[]) => {
    const uploadedFiles = await newImageService.uploadImages(files)
    return uploadedFiles.map((fileName) => ({
      key: `images/${fileName}`,
      url: `${process.env.REACT_APP_S3_BUCKET_URL}/images/${fileName}`
    }))
  }
)

export const deleteImage = createAsyncThunk(
  'images/deleteImage',
  async (imageKey: string) => {
    await newImageService.deleteImage(imageKey)
    return imageKey // Return the deleted image key
  }
)

// Redux Slice
const imageSlice = createSlice({
  name: 'images',
  initialState: {
    images: [] as ImageType[],
    status: 'idle', // 'loading' | 'succeeded' | 'failed'
    error: null as string | null
  },
  reducers: {
    addImages: (state, action: PayloadAction<ImageType[]>) => {
      state.images = [...state.images, ...action.payload]
    },
    removeImage: (state, action: PayloadAction<string>) => {
      state.images = state.images.filter(
        (image) => image.key !== action.payload
      )
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImages.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(
        fetchImages.fulfilled,
        (state, action: PayloadAction<ImageType[]>) => {
          state.status = 'succeeded'
          state.images = action.payload
        }
      )
      .addCase(fetchImages.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || 'Failed to fetch images'
      })
      .addCase(
        uploadImages.fulfilled,
        (state, action: PayloadAction<ImageType[]>) => {
          const newImages = action.payload.filter(
            (newImage) => !state.images.some((img) => img.key === newImage.key)
          )
          state.images = [...state.images, ...newImages]
        }
      )
      .addCase(
        deleteImage.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.images = state.images.filter(
            (image) => image.key !== action.payload
          )
        }
      )
  }
})

export const { addImages, removeImage } = imageSlice.actions

export default imageSlice.reducer
