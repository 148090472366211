import axios from 'axios'
import { type PackageType } from '../utils/types'
import { getApiKey } from '../utils/apiKeyStorage'

const apiBaseUrl = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: apiBaseUrl
})

// Add a request interceptor to include the API key
apiClient.interceptors.request.use(
  async (config) => {
    const apiKey = getApiKey() // Synchronously fetch the API key
    console.log('apiKey ', apiKey)
    if (apiKey) {
      config.headers['x-api-key'] = apiKey // Add the API key to headers
    }
    return await Promise.resolve(config) // Explicitly return an awaited promise
  },
  async (error) => {
    return await Promise.reject(error) // Explicitly await the rejection
  }
)

const createPackage = async (newPackage: any): Promise<PackageType> => {
  try {
    const response = await apiClient.post('/packages', newPackage)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAllPackages = async (): Promise<PackageType[]> => {
  try {
    const response = await apiClient.get<PackageType[]>('/packages')
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updatePackage = async (id: number, newObject: any): Promise<void> => {
  try {
    const response = await apiClient.put(`/packages/${id}`, newObject)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const deletePackage = async (id: number): Promise<void> => {
  try {
    const response = await apiClient.delete(`/packages/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const importPackages = async (file: FormData, imageUrls: any): Promise<void> => {
  try {
    file.append('imageUrls', JSON.stringify(imageUrls))
    const response = await apiClient.post('/packages/import', file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    console.log('File uploaded successfully', response.data)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data) {
      const errorMessages = error.response.data.errors
      if (Array.isArray(errorMessages)) {
        const errorMessageString = errorMessages.join('\n')
        throw new Error(errorMessageString)
      } else {
        throw new Error(error.response.data.message as string)
      }
    } else {
      throw new Error(
        "Une erreur est survenue lors de l'importation des packages."
      )
    }
  }
}

const downloadExportedPackages = async (): Promise<void> => {
  try {
    const response = await apiClient.get('/packages/export', {
      responseType: 'blob'
    })

    const file = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    const fileURL = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', 'fichier_packages.xlsx')
    document.body.appendChild(link)
    link.click()

    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }
    URL.revokeObjectURL(fileURL)
  } catch (error) {
    console.error('Failed to download the file:', error)
  }
}

const disablePackage = async (
  id: number,
  enabled: boolean
): Promise<Partial<PackageType>> => {
  try {
    return await apiClient.put(`/packages/disable/${id}`, { enabled })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Une erreur est survenue lors de la désactivation du pacakge ${id}:`,
      error
    )
    return {}
  }
}

const getPackagesByAudience = async (id: number, accountNumber: string): Promise<PackageType[]> => {
  try {
    const response = await apiClient.get(
      `/packages/package-by-audience/${id}`
    )
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updatePackageRank = async (packageId: number, updatedData: number, audienceId: number): Promise<number> => {
  try {
    // Make a PATCH request to the API to update the package
    const response = await apiClient.patch(`/packages/${packageId}/rank`, { newRank: updatedData, audienceId: audienceId })
    return response.data.newRank // Return the updated package data
  } catch (error) {
    console.error('Error updating package:', error)
    throw error // Propagate the error so it can be handled by the caller
  }
}

const bulkDeletePackages = async (packageIds: number[]): Promise<void> => {
  try {
    await apiClient.delete('/packages/bulk-delete', {
      data: { packageIds },
      headers: { 'Content-Type': 'application/json' }
    })
  } catch (error) {
    console.error('Error deleting packages:', error)
    throw new Error('Error occurred while attempting bulk delete.')
  }
}

export default {
  createPackage,
  getAllPackages,
  updatePackage,
  deletePackage,
  importPackages,
  downloadExportedPackages,
  disablePackage,
  getPackagesByAudience,
  updatePackageRank,
  bulkDeletePackages
}
