import React, { useState } from 'react'
import './add-image.css'
import { type ImageType, type ProductType } from '../../../utils/types'
import productService from '../../../services/productService'
import newImageService from '../../../services/newImageService'
import InfoModal from '../../utils/infoModal/InfoModal'
import AdminButton from '../../utils/adminButton/AdminButton'
import {
  extractImageNameFromUrl,
  getImageUrlFromRedux
} from '../../../utils/utils'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../store/store'

interface AddImageProps {
  closeModal: () => void
  setProducts: (productList: ProductType[]) => void
  currentProduct?: ProductType
  imageUrls: ImageType[]
}

const AddImage: React.FC<AddImageProps> = ({
  closeModal,
  currentProduct,
  imageUrls,
  setProducts
}) => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    currentProduct?.imageUrl
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const images = useSelector((state: RootState) => state.images.images)
  const handleSelectImage = async (imageUrl: string): Promise<void> => {
    setSelectedImage(imageUrl)
  }
  const updateImage = async (): Promise<void> => {
    if (currentProduct && selectedImage) {
      await newImageService
        .updateProductImage(currentProduct.id, selectedImage)
        .then(() => {
          setmodalText('Image mise à jour avec succès')
          setIsModalOpen(true)
        })
      const fetchProducts = async (): Promise<void> => {
        const data = await productService.getAllProducts()
        setProducts(data)
      }
      await fetchProducts()
    }
  }

  return (
    <div className='add-image-container'>
      <div className='current-image-container'>
        <div className='current-image-text'>Image actuelle</div>
        <div className='image-container'>
          <img
            src={getImageUrlFromRedux(images, selectedImage || '')}
            alt='Product'
            className='actual-image-item'
          />
        </div>
      </div>
      <div className='divider'></div>
      <div className='biblio-text'>Bibliothèque d&apos;images</div>
      <div className='image-selection-grid'>
        {[...imageUrls]
          .sort((a, b) => (a.key.includes('image_placeholder') ? -1 : 1))
          .map((image) => (
            <div key={image.key} className='add-image-selection'>
              <img
                src={getImageUrlFromRedux(images, image.key)}
                alt='Product'
                onClick={async () => {
                  await handleSelectImage(image.key)
                }}
                className={`image-item ${selectedImage === image.key && 'selected-image-item'}`}
              />
              <p className='font__body--bold-small'>
                {extractImageNameFromUrl(image.key)}
              </p>
            </div>
          ))}
      </div>
      <AdminButton className='update-image-button' onClick={updateImage}>
        Terminer
      </AdminButton>
      <InfoModal
        isOpen={isModalOpen}
        title='Mise à jours Image'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
          closeModal()
        }}
      />
    </div>
  )
}

export default AddImage
