import React, { useEffect, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import './add-pre-order.css'
import AdminButton from '../../utils/adminButton/AdminButton'
import InfoModal from '../../utils/infoModal/InfoModal'
import { type ProductType } from '../../../utils/types'
import preOrderService from '../../../services/preOrderService'

interface AddPreOrdersProps {
  closeModal: () => void
  onAddPreOrder: (preOrder: ProductType) => void
}

const AddPreOrders: React.FC<AddPreOrdersProps> = ({
  closeModal,
  onAddPreOrder
}) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ProductType>({
    defaultValues: {
      id: 0,
      Audience: null,
      code: '',
      label: '',
      deliveryDate: new Date()
    }
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [productFound, setProductFound] = useState(true)
  const [modalText, setModalText] = useState('')
  const [shouldCloseModal, setShouldCloseModal] = useState(false)

  const idProduit = watch('id')

  useEffect(() => {
    if (idProduit > 0) {
      preOrderService.getPreOrdersById(idProduit)
        .then((data: ProductType) => {
          setProductFound(true)
          setValue('Audience', data.Audience)
          setValue('code', data.code)
          setValue('label', data.label)
          setValue('deliveryDate', data.deliveryDate)
        })
        .catch(() => {
          setProductFound(false)
          setValue('Audience', null)
          setValue('code', '')
          setValue('label', '')
          setValue('deliveryDate', null)
        })
    } else {
      setValue('Audience', null)
      setValue('code', '')
      setValue('label', '')
      setValue('deliveryDate', null)
    }
  }, [idProduit, setValue])

  const onSubmit: SubmitHandler<ProductType> = (data: ProductType) => {
    data.Audience = data.Audience && data.Audience.id === -1 ? null : data.Audience
    if (data.deliveryDate) {
      preOrderService
        .createPreOrder(data.id, data.deliveryDate)
        .then((res) => {
          setModalText('Le pre-Order a été crée avec succès')
          setIsModalOpen(true)
          onAddPreOrder(res)
          setShouldCloseModal(true)
        })
        .catch(() => {
          setModalText('Une erreur est survenue lors de la création du pre-Order')
          setIsModalOpen(true)
        })
    } else {
      setModalText('Le champs Date de livraison est obligatoire')
      setIsModalOpen(true)
    }
  }

  return (
    <>
      <form className='audience-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='inputs-container'>
        <div className='input-form-container'>
            <p className='input-label'>ID Produit</p>
            <input
              className='input-text'
              type='number'
              {...register('id', {
                required: 'Ce champ est obligatoire'
              })}
            />
            {!productFound && (
            <p className='validation-error'>Produit non trouvé</p>
            )}
          </div>
          <div className='input-form-container'>
            <p className='input-label'>Audience</p>
            <input
              className='input-text'
              {...register('Audience.name')}
              disabled
            />
          </div>
          <div className='input-form-container'>
            <p className='input-label'>Code Produit</p>
            <input
              className='input-text'
              {...register('code')}
              disabled
            />

          </div>
          <div className='input-form-container'>
            <p className='input-label'>Libellé PreOrder</p>
            <input
              className='input-text'
              {...register('label')}
              disabled
            />
          </div>
          <div className='input-form-container full-width'>
            <p className='input-label'>Date de Livraison</p>
            <input
              type='date'
              className='input-text input-date-pre-order'
              {...register('deliveryDate', {
                required:
                  'Ce champ est obligatoire'
              })}
            />
            {errors.deliveryDate && (
              <p className='validation-error'>{errors.deliveryDate.message}</p>
            )}
          </div>
        </div>
        <AdminButton type='submit'>Valider</AdminButton>
      </form>
      <InfoModal
        isOpen={isModalOpen}
        title='Ajouter un nouveau pre-order'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
          if (shouldCloseModal) {
            closeModal()
          }
        }}
      />
    </>
  )
}

export default AddPreOrders
