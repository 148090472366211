import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type ReactElement
} from 'react'
import './product-catalog.css'
import ProductFilter from '../../components/productCatalog/productFilter/ProductFilter'
import CatalogProductList from '../../components/productCatalog/catalogProductList/CatalogProductList'
import InfoPanel from '../../components/productCatalog/infoPanel/InfoPanel'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import productService from '../../services/productService'
import { getUniqueBrandList } from '../../services/filterService'
import Pagination from '../../components/productCatalog/pagination/Pagination'
import { ReactComponent as CloseIcon } from '../../assets/close_black.svg'
import { ReactComponent as FilterIcon } from '../../assets/filter.svg'
import Cart from '../../components/cart/Cart'
import { countCart } from '../../services/cartService'
import { ReactComponent as OrderHistoryIcon } from '../../assets/order-history.svg'
import { Link, useNavigate } from 'react-router-dom'
import orderService from '../../services/orderService'
import { updateProductList } from '../../slices/productListSlice'
import packageService from '../../services/packageService'
import { updatePackageList } from '../../slices/packageListSlice'

export interface OrderFrequencyResponse {
  catalogAccess: boolean
  nbrDays?: number
}

const ProductCatalog = (): ReactElement => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const catalogProducts = useAppSelector((state) => state.product.productList)
  const catalogPackages = useAppSelector((state) => state.package.packageList)
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredBrand, setFilteredBrand] = useState<string[]>([])
  const [brandList, setBrandList] = useState<string[]>([])
  const [panelDisplay, setPanelDisplay] = useState<boolean>(false)
  const [filterDisplay, setFilterDisplay] = useState<boolean>(false)
  const productPerPage = 6

  // Used for pagination

  const navigate = useNavigate()

  // Filter standalone products and packages by brand
  const filteredPackages = catalogPackages.filter((pkg) =>
    pkg.products.some(
      (product) =>
        filteredBrand.length === 0 || filteredBrand.includes(product.brand)
    )
  )

  const filteredProducts = catalogProducts.filter(
    (product) =>
      filteredBrand.length === 0 || filteredBrand.includes(product.brand)
  )

  // Combine the filtered standalone products and packages
  const combinedList = [
    ...filteredPackages.map((pkg) => ({ ...pkg, type: 'package' })), // Filtered packages
    ...filteredProducts.map((product) => ({ ...product, type: 'product' })) // Filtered standalone products
  ].sort((a, b) => {
    if (a.type === 'package' && b.type === 'product') return -1
    if (a.type === 'product' && b.type === 'package') return 1
    return 0
  })

  // Pagination for combinedList
  const lastCombinedItemIndex = currentPage * productPerPage
  const firstCombinedItemIndex = lastCombinedItemIndex - productPerPage
  const currentCombinedItems = combinedList.slice(
    firstCombinedItemIndex,
    lastCombinedItemIndex
  )

  useLayoutEffect(() => {
    const data = {
      accountNumber: user.accountNumber,
      audience: user.audience
    }
    const checkOrderFrequencyLimit = async (): Promise<void> => {
      try {
        const res: OrderFrequencyResponse =
          await orderService.checkOrderFrequency(data)
        if (!res.catalogAccess) {
          navigate('/forbidden-order', {
            state: { totalDaysLeftBeforeReOrdering: res.nbrDays }
          })
        }
      } catch (err) {
        console.error('Error checking order frequency:', err)
      }
    }
    void checkOrderFrequencyLimit()
  }, [user])

  /*   useLayoutEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      try {
        const data = await productService.getProductsByAudience(
          user.audience.id,
          currentPage
        )
        const sortedData = data.products.sort((a, b) => {
          if (a.pointValue === null && b.pointValue === null) {
            return 0
          }
          if (a.pointValue === null) {
            return 1
          }
          if (b.pointValue === null) {
            return -1
          }
          return 0
        })
        if (
          sortedData.length > 0 &&
          JSON.stringify(sortedData) !== JSON.stringify(catalogProducts)
        ) {
          dispatch(updateProductList(sortedData))
        } else {
          console.warn('The fetched product list is empty or invalid.')
        }
      } catch (error) {
        console.error('An error occurred while fetching products:', error)
      }
    }
    if (user.audience.id) {
      void fetchProducts()
    }
  }, [user.audience.id, dispatch]) */

  useLayoutEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      try {
        const data = await productService.getProductsByAudience(
          user.audience.id,
          currentPage
        )
        if (
          data.products.length > 0 &&
          JSON.stringify(data.products) !== JSON.stringify(catalogProducts)
        ) {
          dispatch(updateProductList(data.products))
        } else {
          console.warn('The fetched product list is empty or invalid.')
        }
      } catch (error) {
        console.error('An error occurred while fetching products:', error)
      }
    }
    if (user.audience.id) {
      void fetchProducts()
    }
  }, [user.audience.id, dispatch])

  useLayoutEffect(() => {
    const fetchPackages = async (): Promise<void> => {
      try {
        const data = await packageService.getPackagesByAudience(
          user.audience.id, user.accountNumber
        )
        dispatch(updatePackageList(data))
      } catch (error) {
        console.error('An error occurred while fetching products:', error)
      }
    }
    if (user.audience.id) {
      void fetchPackages()
    }
  }, [user.audience.id, dispatch])

  useEffect(() => {
    if (catalogProducts || catalogPackages) {
      const uniqueBrands = getUniqueBrandList(catalogProducts, catalogPackages)
      setBrandList(uniqueBrands)
    }
  }, [catalogProducts, catalogPackages])

  // Added to close mobile menu or modal if window is resized to desktop
  const containerRef = useRef<HTMLDivElement>(null)
  const checkWindowWidth = useCallback((): void => {
    if (window.innerWidth > 1024) {
      setPanelDisplay(false)
      setFilterDisplay(false)
    }
  }, [])

  useEffect((): (() => void) => {
    checkWindowWidth()
    const handleResize = (): void => {
      checkWindowWidth()
    }
    window.addEventListener('resize', handleResize)
    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  }, [checkWindowWidth])

  // Brand Filter function
  const handleFilter = useCallback((brand: string): void => {
    setCurrentPage(1)
    setFilteredBrand((prevFilteredBrand) =>
      prevFilteredBrand.includes(brand)
        ? prevFilteredBrand.filter((item) => item !== brand)
        : [...prevFilteredBrand, brand]
    )
  }, [])

  const handleResetFilter = (): void => {
    setCurrentPage(1)
    setFilteredBrand([])
  }

  const productFilterClass = `product-filter${filterDisplay ? ' is-active' : ''}`
  const closeFilterMenuClass = `close-filter-menu-icon${filterDisplay ? ' is-active' : ''}`
  const productCatalogContainerClass = `product-catalog-container ${filterDisplay || panelDisplay ? ' menu-display' : ''}`

  return (
    <div ref={containerRef} className={productCatalogContainerClass}>
      <div
        className={`order-history-container order-history-wording mobile ${filterDisplay ? ' hide-history' : ''}`}
      >
        <Link to='/order-list' className='order-history-link-header mobile'>
          <OrderHistoryIcon />
          <span className='order-history-text'>Historique de commandes</span>
        </Link>
      </div>
      <div className='product-filter-container'>
        <div
          className={`filter-button ${filterDisplay ? ' hide-history' : ''}`}
          onClick={() => {
            setFilterDisplay(true)
          }}
        >
          <span className='font__body--bold-large'>Filtres</span>
          <FilterIcon />
        </div>
        <div className={productFilterClass}>
          <ProductFilter
            filteredBrand={filteredBrand}
            brandList={brandList}
            handleFilter={handleFilter}
            handleResetFilter={handleResetFilter}
          />
          <span
            className={closeFilterMenuClass}
            onClick={() => {
              setFilterDisplay(false)
            }}
          >
            <CloseIcon />
          </span>
        </div>
      </div>
      <div className='product-catalog'>
        <div className='order-history-container desktop'>
          <Link to='/order-list' className='order-history-link-header'>
            <OrderHistoryIcon />
            <span className='font__body--large'>Historique de commandes</span>
          </Link>
        </div>
        <CatalogProductList catalogItems={currentCombinedItems} />
        <Pagination
          currentPage={currentPage}
          productList={combinedList}
          productPerPage={productPerPage}
          isActivePagination={filterDisplay}
          setCurrentPage={setCurrentPage}
        >
          {' '}
          <div className='second-cart-button'>
            <Cart countCart={countCart()} />
          </div>
        </Pagination>
      </div>
      <div className='info-panel-cart-container'>
        <InfoPanel
          isInProductCatalog
          panelDisplay={panelDisplay}
          setPanelDisplay={setPanelDisplay}
        />
        <Cart countCart={countCart()} />
      </div>
    </div>
  )
}

export default ProductCatalog
