import axios from 'axios'
import { type ImageType } from '../utils/types'

const apiBaseUrl = process.env.REACT_APP_API_URL

const uploadImages = async (files: File[]): Promise<string[]> => {
  try {
    // ✅ Generate filenames
    const fileNames = files.map((file) => {
      const randomNumber = Math.floor(100000 + Math.random() * 900000)
      const fileExtension = file.name.split('.').pop()
      const baseName = file.name
        .split('.')
        .slice(0, -1)
        .join('.')
        .replace(/\s+/g, '_') // Remove spaces

      return `image__${baseName}__${randomNumber}.${fileExtension}`
    })

    const { data } = await axios.post(
      `${apiBaseUrl}/images/get-presigned-urls`,
      {
        fileNames
      }
    )

    if (!data?.preSignedUrls) {
      throw new Error('Failed to fetch pre-signed URLs')
    }

    await Promise.all(
      files.map(async (file, index) => {
        const { url, fields } = data.preSignedUrls[index]
        const formData = new FormData()

        Object.entries(fields).forEach(([key, value]) => {
          formData.append(key, value as string)
        })

        // ✅ Convert file to Blob
        const fileBlob = new Blob([file], { type: file.type })
        formData.append('file', fileBlob)

        await axios.post(url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      })
    )

    console.log('Files uploaded successfully')
    return fileNames // Return filenames so frontend can update state
  } catch (error) {
    console.error('Error uploading files:', error)
    throw error
  }
}

/**
 * Fetches a list of image keys from the backend.
 * @returns An array of image keys (filenames).
 */
const listImages = async (): Promise<ImageType[]> => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/images/list-images`)
    return data.images
  } catch (error) {
    console.error('Error fetching images:', error)
    return []
  }
}

/**
 * Fetches a pre-signed URL from the backend to display an image.
 * @param imageKey The key (filename) of the image stored in S3.
 * @returns A pre-signed URL to access the image.
 */
const getImageUrl = async (imageKey: string): Promise<string> => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/images/get-presigned-url`, {
      params: { key: imageKey }
    })
    return data.url // The pre-signed URL from the backend
  } catch (error) {
    console.error('Error fetching signed URL:', error)
    return ''
  }
}

const deleteImage = async (imageKey: string): Promise<void> => {
  try {
    await axios.delete(`${apiBaseUrl}/images/delete-image`, {
      data: { imageKey }
    })

    console.log('Image deleted successfully:', imageKey)
  } catch (error) {
    console.error('Error deleting image:', error)
    throw error
  }
}

// Update product image using the image key
const updateProductImage = async (
  productId: number,
  imageUrl: string
): Promise<void> => {
  console.log('url', imageUrl)
  try {
    await axios.patch(`${apiBaseUrl}/products/update-image/${productId}`, {
      imageUrl
    })
    console.log('API call successful')
  } catch (error) {
    console.error('API call failed:', error)
  }
}

const updatePackageImage = async (
  packageId: number,
  imageUrl: string
): Promise<void> => {
  try {
    await axios.patch(`${apiBaseUrl}/packages/update-image/${packageId}`, {
      imageUrl
    })
    console.log('API call successful')
  } catch (error) {
    console.error('API call failed:', error)
  }
}

export default {
  uploadImages,
  listImages,
  getImageUrl,
  deleteImage,
  updateProductImage,
  updatePackageImage
}
