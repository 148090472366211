import axios from 'axios'
import { type AudienceType } from '../utils/types'

const apiBaseUrl = process.env.REACT_APP_API_URL

const createAudience = async (
  newAudience: Partial<AudienceType>
): Promise<AudienceType> => {
  try {
    const response = await axios.post(`${apiBaseUrl}/audiences`, newAudience)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAllAudiences = async (): Promise<AudienceType[]> => {
  try {
    const response = await axios.get<AudienceType[]>(`${apiBaseUrl}/audiences`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

export const deleteAudience = async (id: number): Promise<void> => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/audiences/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updateAudience = async (id: number, newObject: AudienceType): Promise<void> => {
  try {
    const response = await axios.put(`${apiBaseUrl}/audiences/${id}`, newObject)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAudienceByName = async (
  name: string
): Promise<AudienceType> => {
  try {
    const response = await axios.get(`${apiBaseUrl}/audiences/audience/${name}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

export default {
  createAudience,
  getAllAudiences,
  deleteAudience,
  updateAudience,
  getAudienceByName
}
