import React, { useEffect, useState } from 'react'
import './list-pre-order.css'
import { type ProductType } from '../../../utils/types'
import AdminTable from '../../utils/adminTable/AdminTable'
import InfoModal from '../../utils/infoModal/InfoModal'
import Modal from '../../utils/modal/Modal'
import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg'
import ConfirmationModal from '../../utils/confirmationModal/ConfirmationModal'
import preOrderService from '../../../services/preOrderService'
import EditPreOrder from '../editPreOrder/EditPreOrder'

interface ListPreOrderProps {
  preOrdersList: ProductType[]
}

const ListPreOrder: React.FC<ListPreOrderProps> = ({ preOrdersList }) => {
  const [preOrders, setPreOrders] = useState<ProductType[]>(preOrdersList)
  const [selectedPreOrder, setSelectedPreOrder] = useState<Partial<ProductType>>({
    brand: '',
    code: '',
    label: '',
    minToOrder: null,
    maxToOrder: null,
    minToWinPoints: null,
    maxToWinPoints: null,
    pointValue: null
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [
    isBulkDeleteConfirmationModalOpen,
    setIsBulkDeleteConfirmationModalOpen
  ] = useState(false)
  const [selectedPreOrders, setSelectedPreOrders] = useState<number[]>([])
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false)

  const columns = [
    { header: 'ID PRODUIT', accessor: 'id' },
    {
      header: 'AUDIENCE',
      accessor: 'audience',
      render: (row: ProductType) => <>{row.Audience?.name || '--'}</>
    },
    { header: 'CODE PRODUIT', accessor: 'code' },
    { header: 'LIBELLE PRODUIT', accessor: 'label' },
    { header: 'DATE DE LIVRAISON', accessor: 'deliveryDate', render: (row: ProductType) => <>{row.deliveryDate && formatDateToFrench(row.deliveryDate.toString())}</> },
    {
      header: 'ACTIONS',
      accessor: 'actions',
      render: (row: ProductType) => (
        <div className='actions-cell'>
          <EditIcon
            className='admin-icons'
            onClick={() => {
              updatePreOrder(row)
            }}
            style={{ color: 'green' }}
          >
            Update
          </EditIcon>
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              setSelectedPreOrder(row)
              setIsDeleteConfirmationModalOpen(true)
            }}
          >
            Delete
          </DeleteIcon>
        </div>
      )
    }
  ]

  const formatDateToFrench = (isoDate: string): string => {
    const date = new Date(isoDate)
    const day = date.getUTCDate().toString().padStart(2, '0')
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
    const year = date.getUTCFullYear().toString()
    return `${day}/${month}/${year}`
  }

  const confirmToggle = async (): Promise<void> => {
    if (selectedPreOrder?.id) {
      const newEnabledStatus = !selectedPreOrder.enabled
      await preOrderService.disablePreOrder(selectedPreOrder.id, newEnabledStatus)
      const updatedPreOrders = preOrders.map((p) =>
        p.id === selectedPreOrder.id ? { ...p, enabled: newEnabledStatus } : p
      )
      setPreOrders(updatedPreOrders)
    }
    setIsConfirmationModalOpen(false)
  }

  const cancelToggle = (): void => {
    setIsConfirmationModalOpen(false)
  }

  const deletePreOrderAction = async (id: number): Promise<void> => {
    try {
      await preOrderService.deletePreOrder(id).then(async () => {
        setmodalText('Le pre-order a été supprimé avec succès')
        setIsModalOpen(true)
        const response = await preOrderService.getAllPreOrders()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setPreOrders(response)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updatePreOrder = (preOrder: ProductType): void => {
    setSelectedPreOrder(preOrder)
    setIsEditModalOpen(true)
  }

  const handleEditPreOrder = async (): Promise<void> => {
    try {
      const response = await preOrderService.getAllPreOrders()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setPreOrders(response)
    } catch (error) {
      console.error('Failed to fetch audiences:', error)
    }
  }

  useEffect(() => {
    setPreOrders(preOrdersList)
  }, [preOrdersList])

  return (
    <div className='preOrders-container-list'>
      <AdminTable columns={columns} data={preOrders} />

      <InfoModal
        isOpen={isModalOpen}
        title='Supprimer le produit'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
      <Modal
        isOpen={isEditModalOpen}
        title='Modifier un pre-order'
        onClose={() => {
          setIsEditModalOpen(false)
        }}
      >
        <EditPreOrder
          preOrderProp={selectedPreOrder}
          closeModal={() => {
            setIsEditModalOpen(false)
          }}
          onEditPreOrder={handleEditPreOrder}
        />
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title="Indication d'un produit en rupture"
        message={
          selectedPreOrder.enabled
            ? 'Attention, le produit ne sera plus visible par le point de vente.'
            : 'Le produit sera de nouveau visible pour le point de vente.'
        }
        subMessage={'Confirmez-vous ?'}
        onClose={cancelToggle}
        onConfirme={confirmToggle}
      ></ConfirmationModal>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        title='Supprimer un pre-order'
        message="ATTENTION, toute suppression de produit pre-order dont la date de livraison n'a pas été dépassée se verra retiré de toutes les commandes passées, etes-vous sur?"
        onClose={() => {
          setIsDeleteConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          if (selectedPreOrder.id) {
            await deletePreOrderAction(selectedPreOrder.id)
            setIsDeleteConfirmationModalOpen(false)
          }
        }}
      ></ConfirmationModal>
      <ConfirmationModal
        isOpen={isBulkDeleteConfirmationModalOpen}
        title='Confirmer la suppression en masse'
        message={`Êtes-vous sûr de vouloir supprimer les produits suivants: ${selectedPreOrders.join(', ')}?`}
        onClose={() => { setIsBulkDeleteConfirmationModalOpen(false) } }
        onConfirme={async () => {
          try {
            await preOrderService.bulkDeletePreOrders(selectedPreOrders)
            setSelectedPreOrders([]) // Clear selected preOrders
            const updatedPreOrders = await preOrderService.getAllPreOrders()
            setPreOrders(updatedPreOrders)
            setIsBulkDeleteConfirmationModalOpen(false) // Close modal after deletion
          } catch (error) {
            console.log('Failed to delete preOrders')
          }
        }}
      />
    </div>
  )
}

export default ListPreOrder
