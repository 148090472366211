import React, { useEffect, useState } from 'react'
import './cart-panel-package-card.css'
import { type PackageType } from '../../../utils/types'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { updateTotalPackagePoints } from '../../../slices/cartSlice'
import { getPackageTotalPoints } from '../../../services/pointsService'
import HandlePackageCartButton from '../../utils/handlePackageCartButton/HandlePackageCartButton'
import PackageDetailsModal from '../../utils/packageDetailsModal/PackageDetailsModal'
import MacaronPoints from '../../utils/macaronPoints/macaronPoints'
import GoldenTickerImg from '../../../assets/golden-ticket.png'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../store/store'
import { getImageUrlFromRedux } from '../../../utils/utils'

interface CartPanelPackageCardProps {
  pkg: PackageType
}

const CartPanelPackageCard: React.FC<CartPanelPackageCardProps> = ({ pkg }) => {
  const dispatch = useAppDispatch()
  const packagesList = useAppSelector((state) => state.cart.packages)
  const [prevPointsValue, setPrevPointsValue] = useState<number | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const pointsValue = getPackageTotalPoints(pkg)
  const images = useSelector((state: RootState) => state.images.images)

  const getPackageQuantity: () => any = () => {
    const foundPackage = packagesList.find((item) => item.id === pkg.id)
    return foundPackage ? foundPackage.quantity : 0
  }

  useEffect(() => {
    console.log('pkg', pkg)
    if (prevPointsValue !== pointsValue) {
      dispatch(updateTotalPackagePoints({ pkg, pointsValue }))
      setPrevPointsValue(pointsValue)
    }
  }, [pkg])

  return (
    <>
      <td className='image cart-card__info'>
        <img className='package-cart-img' src={getImageUrlFromRedux(images, pkg.imageUrl || '')} />
      </td>
      <td className='code cart-card__info code-package'>-</td>
      <td className='name cart-card__info'>{pkg.label}</td>

      <td className='quantity cart-card__info-package'>
        <div
          className='package-details-button cart-card__info font__body--small'
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          <p>Détails</p>
        </div>
        <HandlePackageCartButton
          pkg={pkg}
          getPackageQuantity={getPackageQuantity}
        ></HandlePackageCartButton>
      </td>
      <td className='points cart-card__info'>
        {pointsValue && pkg.pointValue !== 0 && pkg.totalPoints ? (
          <MacaronPoints points={pointsValue} />
        ) : (
          pkg.pointValue === 0 && (
            <div className='golden-ticket-cart'>
              <img src={GoldenTickerImg} width={100} height={40} />
            </div>
          )
        )}
      </td>
      <PackageDetailsModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        packageName={`Contenu du Pack ${pkg.label}`}
        products={pkg.products}
      />
    </>
  )
}

export default CartPanelPackageCard
