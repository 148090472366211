import React from 'react'
import './order-detail.css'
import { type ProductType } from '../../utils/types'
import { useSelector } from 'react-redux'
import { type RootState } from '../../store/store'
import { getImageUrlFromRedux } from '../../utils/utils'

interface OrderDetailProps {
  products: ProductType[]
}

const OrderDetail: React.FC<OrderDetailProps> = (props) => {
  const images = useSelector((state: RootState) => state.images.images)

  const getStatus = (status: string): string => {
    const statusMap: Record<string, string> = {
      PENDING: 'En cours',
      CONFIRMED: 'Confirmé',
      CANCELLED: 'Rejeté'
    }
    return statusMap[status] || 'En cours'
  }

  const getStatusClass = (status: string): string => {
    const statusMap: Record<string, string> = {
      PENDING: 'shipped',
      CONFIRMED: 'delivered',
      CANCELLED: 'canceled'
    }
    return statusMap[status] || 'En cours'
  }
  return (
    <div className='order-detail-container'>
      <div className='oder-detail-list'>
        <table className='order-detail-array'>
          <thead>
            <tr className='font__body--bold-medium'>
              <th scope='col'></th>
              <th id="ref" className='order-detail-ref-title' scope='col'>Ref.</th>
              <th id="product" className='order-detail-label-title' scope='col'>Produit</th>
              <th id="quantity" className='order-detail-quantity' scope='col'>Quantités</th>
              <th id="status" className='order-detail-status-title' scope='col'>Statut</th>
            </tr>
          </thead>
          <tbody className='history-details-body'>
            {props.products?.map((product) => (
              <tr key={product.id} className='order-detail-line'>
                <td className='product-cart-img-container'>
                  <img className='product-cart-img' src={getImageUrlFromRedux(images, product.imageUrl || '')} />
                </td>
                <td className='order-detail-ref'>{product.code}</td>
                <td className='order-detail-label'>{product.label}</td>
                <td className='order-detail-quantity'>{product.quantity}</td>
                <td className={`order-detail-status ${getStatusClass(product.status || 'shipped')} font__body--bold-medium`}>
                  {product.status && getStatus(product.status)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OrderDetail
