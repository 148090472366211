import React, { useEffect, useState, type DragEvent } from 'react'
import AdminButton from '../utils/adminButton/AdminButton'
import './images-library.css'
import { ReactComponent as DeleteIcon } from '../../assets/remove_icon.svg'
import { ReactComponent as ImportIcon } from '../../assets/import_icon.svg'
import InfoModal from '../utils/infoModal/InfoModal'
import ConfirmationModal from '../utils/confirmationModal/ConfirmationModal'
import { extractImageNameFromUrl } from '../../utils/utils'
import Spinner from '../utils/spinner/Spinner'
import { type RootState, type AppDispatch } from '../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteImage,
  fetchImages,
  uploadImages
} from '../../slices/imageSlice'

const ImagesLibrary: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
  const [fileToDelete, setFileToDelete] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [modalText, setModalText] = useState('')
  const [isPending, setIsPending] = useState(false)
  const { images, status } = useSelector((state: RootState) => state.images)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    void dispatch(fetchImages())
  }, [dispatch])

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files) {
      setSelectedFiles(event.target.files)
    }
  }

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    if (!selectedFiles || selectedFiles.length === 0) {
      setModalText('Aucune image sélectionnée')
      setIsModalOpen(true)
      return
    }

    setIsPending(true)
    let fileFormatCheck = false

    try {
      const validFiles = Array.from(selectedFiles).filter((file) => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          fileFormatCheck = true
          return false
        }
        return true
      })

      if (validFiles.length > 0) {
        await dispatch(uploadImages(validFiles)).unwrap()
        await dispatch(fetchImages())

        setModalText(
          fileFormatCheck
            ? "Certaines images n'ont pas été importées car leur type n'est pas conforme. Les autres ont été importées avec succès"
            : 'Images importées avec succès !'
        )
      } else {
        setModalText("Le type de l'image importée n'est pas conforme")
      }
    } catch (err) {
      console.error('Error while uploading images:', err)
      setModalText("Erreur lors de l'upload des images")
    }

    setIsPending(false)
    setIsModalOpen(true)
    setSelectedFiles(null)
  }

  const handleDeleteImage = async (imageKey: string): Promise<void> => {
    if (!imageKey) return

    try {
      await dispatch(deleteImage(imageKey)).unwrap()
    } catch (error) {
      console.error('Failed to delete image:', error)
    }
  }

  const onDrop = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault()
    if (e.dataTransfer.files.length > 0) {
      setSelectedFiles(e.dataTransfer.files)
    }
  }

  const onDragOver = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault()
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '100px'
      }}
    >
      <form
        style={{
          width: '100%',
          position: 'relative'
        }}
        onSubmit={handleSubmit}
      >
        <label
          className={`label ${
            selectedFiles ? 'border-green-500' : 'border-gray-300'
          }`}
          htmlFor='file_upload'
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <span className='span-container'>
            <ImportIcon
              className={`icon ${
                selectedFiles ? 'text-green-600' : 'text-gray-600'
              }`}
            />
            <span
              className={`font-medium ${
                selectedFiles ? 'text-green-600' : 'text-gray-600'
              }`}
            >
              {selectedFiles
                ? Array.from(selectedFiles)
                  .map((file) => file.name)
                  .join(', ')
                : 'Importer des Images'}
            </span>
          </span>
          <input
            className='hidden'
            name='file_upload'
            onChange={handleFileChange}
            type='file'
            id='file_upload'
            accept='image/jpeg, image/png'
            multiple
          />
        </label>
        <AdminButton type='submit' className='import-button'>
          <div className='confirmation-button-wrapper'>
            {isPending && <Spinner />}
            <span>{!isPending ? 'IMPORTER' : 'Import en cours...'}</span>
          </div>
        </AdminButton>
      </form>
      {status === 'loading' ? (
        <Spinner />
      ) : (
        <div className='image-library-container'>
          {images.filter((image) => !image.key.includes('image_placeholder')).map((image) => (
            <div key={image.key} className='library-image-card-container'>
              <div className='library-image-card'>
                <img
                  src={image.url}
                  alt='Uploaded'
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                  className='library-image'
                />
                <DeleteIcon
                  style={{
                    position: 'absolute',
                    top: 4,
                    right: 2,
                    cursor: 'pointer'
                  }}
                  onClick={async () => {
                    setFileToDelete(image.key)
                    setIsConfirmationModalOpen(true)
                  }}
                >
                  Delete
                </DeleteIcon>
              </div>
              <p className='font__body--bold-medium'>
                {extractImageNameFromUrl(image.key)}
              </p>
            </div>
          ))}
        </div>
      )}

      <InfoModal
        isOpen={isModalOpen}
        title='Import Images'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title='Supprimer une image'
        message='Voulez-vous supprimer cette image ?'
        onClose={() => {
          setIsConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          await handleDeleteImage(fileToDelete)
          setIsConfirmationModalOpen(false)
        }}
      ></ConfirmationModal>
    </div>
  )
}

export default ImagesLibrary
