import React, {
  useCallback,
  useEffect,
  useState,
  type ReactElement
} from 'react'
import './cart-panel.css'
import cartService, { getTotalPointInCart } from '../../services/cartService'
import orderService from '../../services/orderService'
import CartPanelCard from '../../components/cartPanel/cartPanelCard/cartPanelCard'
import { useSelector } from 'react-redux'
import {
  type PackageType,
  type AudienceType,
  type ProductType,
  type OrderArchiveType
} from '../../utils/types'
import MainButton from '../../components/utils/mainButton/MainButton'
import { ReactComponent as CartIconWhite } from '../../assets/cart_white.svg'
import { ReactComponent as CartIconBlack } from '../../assets/cart_black.svg'
import { ReactComponent as GoldenTicketInfoIcon } from '../../assets/info_picto.svg'
import { ReactComponent as Box } from '../../assets/box.svg'
// import { ReactComponent as ArrowUp } from '../../assets/arrow-up.svg'
import ArrowUp2 from '../../assets/arrow-up.gif'
import { Link, useNavigate } from 'react-router-dom'
import InfoPanel from '../../components/productCatalog/infoPanel/InfoPanel'
import { ReactComponent as InfoIcon } from '../../assets/question_mark.svg'
import InfoModal from '../../components/utils/infoModal/InfoModal'
import Spinner from '../../components/utils/spinner/Spinner'
import CartPanelPackageCard from '../../components/cartPanel/cartPanelPackageCard/CartPanelPackage'
const CartPanel = (): ReactElement => {
  const [panelDisplay, setPanelDisplay] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
  const [displayGoldenTicketInfo, setDisplayGoldenTicketInfo] =
    useState<boolean>(false)
  const [isPending, setIsPending] = useState(false)
  const [hasPromotionProducts, setHasPromotionProducts] =
    useState<boolean>(false)
  const navigate = useNavigate()
  const products = useSelector((state: any) => state.cart.products)
  const packages = useSelector((state: any) => state.cart.packages)
  const audienceProducts = useSelector(
    (state: any) => state.product.productList
  )
  const audiencePackages = useSelector(
    (state: any) => state.package.packageList
  )
  const user = useSelector((state: any) => state.user)
  const number = cartService.countCart()
  const isValidOrderData = orderService.validateOrderData(
    user.audience as AudienceType,
    number
  )

  const checkWindowWidth = useCallback((): void => {
    if (window.innerWidth > 1280) {
      setPanelDisplay(false)
    }
  }, [])

  const handleResizeWidth = (): void => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResizeWidth)

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResizeWidth)
    }
  }, [])

  useEffect((): (() => void) => {
    checkWindowWidth()
    const handleResize = (): void => {
      checkWindowWidth()
    }
    window.addEventListener('resize', handleResize)
    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  }, [checkWindowWidth])

  useEffect(() => {
    // Check for golden ticket access in products and packages
    const hasGoldenTicketAccess = products.some(
      (product: ProductType) =>
        (product.totalPoints && product.totalPoints !== null && product.totalPoints > 0) || product.pointValue === 0
    )

    const hasGoldenTicketPackageAccess = packages.some(
      (pkg: PackageType) => (pkg.pointValue !== null && pkg.pointValue === 0) || (pkg.totalPoints && pkg.totalPoints !== null && pkg.totalPoints > 0)
    )

    // Set displayGoldenTicketInfo based on product/package golden ticket access
    setDisplayGoldenTicketInfo(
      !hasGoldenTicketAccess && !hasGoldenTicketPackageAccess
    )

    // Check if audience products or packages contain promotion points
    const containsPromotionProducts = audienceProducts.some(
      (product: ProductType) =>
        product.pointValue !== null && product.pointValue > 0
    )

    const containsPromotionPackages = audiencePackages.some(
      (pkg: PackageType) => pkg.pointValue !== null && pkg.pointValue >= 0
    )

    // Set promotion product/package flags
    setHasPromotionProducts(
      containsPromotionProducts || containsPromotionPackages
    )
  }, [products, packages, audienceProducts, audiencePackages])

  const toggleCheckbox = (): void => {
    setIsChecked(!isChecked)
  }
  const confirmOrder = (): void => {
    const totalOrderPoint = getTotalPointInCart(
      products as ProductType[],
      packages as PackageType[]
    )
    if (isChecked && !isPending) {
      setIsPending(true)
      if (isValidOrderData.isValid) {
        const formattedOrderData = orderService.formatOrderData(
          products as ProductType[],
          packages as PackageType[],
          user.accountNumber as string,
          user.audience.name as string,
          totalOrderPoint,
          user.contactId as string,
          user.touchPoint as string,
          user.accoundEmail as string,
          user.accoundLastName as string,
          user.accoundFirstName as string,
          user.shopName as string,
          user.shopAddress as string,
          user.audience as AudienceType
        )
        const archiveOrder: OrderArchiveType = {
          accountNumber: user.accountNumber,
          audienceType: user.audience.name,
          packages: packages,
          products: products,
          totalPoints: totalOrderPoint
        }
        orderService
          .addOrder(formattedOrderData)
          .then(async (res) => {
            await orderService.addArchiveOrder(archiveOrder).then(() => { console.log('Order Archived') })
            setIsPending(false)
            navigate('/order-confirmation')
          })
          .catch((error) => {
            if (error.message === 'Order cannot be placed yet.') {
              setmodalText(`Commande impossible, il semblerait que vous ayez déjà passé commande ${user.audience.frequency === 1 ? 'ce jour' : 'les ' + user.audience.frequency + ' derniers jours'}. \n Merci de prendre contact avec notre support commercial pour obtenir plus d'informations.`)
            } else {
              setmodalText('Une erreur est survenue lors de la création du commande')
            }
            setIsModalOpen(true)
            setIsPending(false)
          })
      } else {
        setIsPending(false)
        setmodalText(isValidOrderData.message)
        setIsModalOpen(true)
      }
    }
  }

  return (
    <>
      <div className='cart-panel-container'>
        <div className='cart'>
          <div className='cart__header'>
            <div className='cart__header--title'>
              <div className='cart__header--title-container'>
                <CartIconBlack className='back-button-icon' />
                <span className='total-order'>{number}</span>
              </div>
              <h2>Mon panier</h2>
            </div>
            <div className='info-panel-back-button-container'>
              <div
                className='info-panel-icon mobile'
                onClick={() => {
                  setPanelDisplay(true)
                }}
              >
                <InfoIcon />
              </div>
              <Link to='/product-catalog'>
                <MainButton className='cart__header--back-button'>
                  <CartIconWhite className='back-button-icon' />
                  Revenir au catalogue
                </MainButton>
              </Link>
            </div>
          </div>
          <div className='cart__cards-list'>
            <table>
              <div className='table-body-container'>
                <thead>
                  <tr>
                    <th scope='col'></th>
                    <th id='ref' scope='col'>
                      Ref.
                    </th>
                    <th id='product' scope='col'>
                      Produit
                    </th>
                    <th
                      id='quantity'
                      className='cart-panel-table-header'
                      scope='col'
                    >
                      Quantités
                    </th>
                    <th scope='col'></th>
                  </tr>
                </thead>
              </div>
              <div className='table-body-container'>
                <tbody>
                  {packages?.map((pkg: PackageType) => (
                    <tr key={pkg.id}>
                      <CartPanelPackageCard pkg={pkg} />
                    </tr>
                  ))}
                  {products?.map((product: ProductType) => (
                    <tr key={product.id}>
                      <CartPanelCard product={product} />
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
            <div className='cart_footer'>
              {displayGoldenTicketInfo && hasPromotionProducts && (
                <div className='golden-ticket-access-info'>
                  <GoldenTicketInfoIcon />
                  <p className='golden-ticket-access-label font__body--bold-medium'>
                    Commandez nos offres du mois pour bénéficier de l&apos;accès
                    quotidien au Ticket d&apos;or dès le 1er jour ouvré du mois
                    prochain, jusqu&apos;au dernier jour du mois concerné.
                  </p>
                </div>
              )}
              <div className='cart__cards-list--agreement'>
                <div className='agreement-checkbox'>
                  <input
                    type='checkbox'
                    checked={isChecked}
                    onChange={toggleCheckbox}
                    name='agreement'
                    className='input-checkbox'
                    id='agreement'
                  />
                  <label htmlFor='agreement' className='agreement'>
                    Bon pour accord
                  </label>
                </div>
                <MainButton
                  className={`validation-button ${(!isChecked || isPending) && 'disabled-button'}`}
                  onClick={confirmOrder}
                >
                  <div className='confirmation-button-wrapper'>
                    {isPending && <Spinner />}
                    <span>Valider</span>
                  </div>
                </MainButton>
              </div>
            </div>
          </div>
          {screenWidth < 1024 ? (
            <div className='scroll-top-container'>
              <div className='gif-container font__title--h2'>
                <img src={ArrowUp2} className='arrow-up-gif' alt='Arrow Up' />
                <Box className='box-svg' />
                <span>Ah, presque !</span>
                <span>ça se passe juste au dessus</span>
              </div>
            </div>
          ) : (
            <div className='scroll-top-container'>
              <Box className='box-svg' />
              <div className='gif-container font__title--h1'>
                <img src={ArrowUp2} className='arrow-up-gif' alt='Arrow Up' />
                <span>Ah, presque !</span>
                <span>Ça se passe juste au dessus</span>
              </div>
            </div>
          )}
        </div>
        <InfoPanel
          panelDisplay={panelDisplay}
          setPanelDisplay={setPanelDisplay}
        />
        <InfoModal
          isOpen={isModalOpen}
          title='Confirmation de commande'
          className='info-modal-overlay-pos'
          message={modalText}
          onClose={() => {
            setIsModalOpen(false)
          }}
        />
      </div>
    </>
  )
}

export default CartPanel
