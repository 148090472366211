import React from 'react'
import './package-details-modal.css'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../store/store'
import { getImageUrlFromRedux } from '../../../utils/utils'

interface PackageDetailsModalProps {
  isOpen: boolean
  onClose: () => void
  packageName: string
  products: any[]
}

const PackageDetailsModal: React.FC<PackageDetailsModalProps> = ({
  isOpen,
  onClose,
  packageName,
  products
}) => {
  const images = useSelector((state: RootState) => state.images.images)
  if (!isOpen) return null

  return (
    <div className='modal-overlay-package'>
      <div className='modal-content-package'>
        <div className='modal-header-package'>
          <h2>{packageName}</h2>
          <button onClick={onClose} className='close-btn-package'>
            &times;
          </button>
        </div>
        <div className='modal-body-package'>
          <table className='package-details-table'>
            <thead>
              <tr>
                <th className='package-details-table-th'></th>
                <th className='package-details-table-th'>Ref.</th>
                <th className='package-details-table-th'>Produit</th>
                <th className='package-details-table-th-quantity'>Quantités</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  <td className='package-details-table-td font__body--bold-small'>
                    <img
                      className='product-cart-img'
                      src={getImageUrlFromRedux(images, product.imageUrl)}
                    />
                  </td>
                  <td className='package-details-table-td font__body--bold-small'>{product.code}</td>
                  <td className='package-details-table-td font__body--bold-small'>{product.label}</td>
                  <td className='package-details-table-td-quantity font__body--bold-small'>
                    <input value={product.PackageProducts.quantity}></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PackageDetailsModal
